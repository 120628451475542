import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RequireAuthGuard } from '@shared/guards/require-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./feature/site/site.module').then(m => m.SiteModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./feature/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'register/action',
    loadChildren: () => import('./feature/registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./feature/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./feature/ecommerce/ecommerce.module').then(m => m.EcommerceModule)
  },
  {
    path: 'my-profile',
    canActivate: [RequireAuthGuard],
    loadChildren: () => import('./feature/panel/panel.module').then(m => m.PanelModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
